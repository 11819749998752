<template>
  <div id="aircraftDetail" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-36">Aircraft Details</div>
        <div class="T-size-20 tag tagStatusActive">Status : Active</div>
        <!-- tagStatusActive,tagStatusMaintenance,tagStatusUnactive -->
      </div>
    </div>
    <div id="infoSeat" class="wrap-Main">
      <div id="aircraftInfo" class="box-S2">
        <div class="box-S4">
          <div class="N-Page T-size-24">Aircrafts Information</div>
        </div>
        <div class="box-S4 flex-between">
          <div class="box-S2 noPadding">
            <div class="B-carddetail">
              <div class="b-row flex-between">
                <div class="N-Page">Aircraft Name</div>
                <div class="t-detail">HS-UOI</div>
              </div>
            </div>
          </div>
          <div class="box-S2 noPadding">
            <div class="B-carddetail">
              <div class="b-row flex-between">
                <div class="N-Page">Aircraft Model</div>
                <div class="t-detail">AW139</div>
              </div>
            </div>
          </div>
        </div>
        <div class="box-S4 flex-between">
          <div class="box-S2 noPadding">
            <div class="B-carddetail">
              <div class="b-row flex-between">
                <div class="N-Page">Total Seat</div>
                <div class="t-detail">12</div>
              </div>
            </div>
          </div>
          <div class="box-S2 noPadding">
            <div class="B-carddetail">
              <div class="b-row flex-between">
                <div class="N-Page">Limit Weight</div>
                <div class="t-detail">6800</div>
              </div>
            </div>
          </div>
        </div>
        <div id="usageData" class="box-S4 flex-column">
          <div class="box-S4">
            <div class="N-Page T-size-24">Usage Data</div>
          </div>
          <div class="box-S4 flex-between">
            <div class="box-S2 noPadding">
              <div class="B-carddetail">
                <div class="b-row flex-between">
                  <div class="N-Page">A/C HRS</div>
                  <div class="t-detail">4499:57</div>
                </div>
              </div>
            </div>
            <div class="box-S2 noPadding">
              <div class="B-carddetail">
                <div class="b-row flex-between">
                  <div class="N-Page">A/C LND</div>
                  <div class="t-detail">6238</div>
                </div>
              </div>
            </div>
          </div>
          <div class="box-S4 flex-between">
            <div class="box-S2 noPadding">
              <div class="B-carddetail">
                <div class="b-row flex-between">
                  <div class="N-Page">#1 HRS</div>
                  <div class="t-detail">4499:57</div>
                </div>
              </div>
            </div>
            <div class="box-S2 noPadding">
              <div class="B-carddetail">
                <div class="b-row flex-between">
                  <div class="N-Page">#2 HRS</div>
                  <div class="t-detail">4499:57</div>
                </div>
              </div>
            </div>
          </div>
          <div class="box-S4 flex-between">
            <div class="B-carddetail">
              <div class="b-row flex-between">
                <div class="N-Page">Updates</div>
                <div class="t-detail">01/07/2020</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="seatLayout" class="box-S2 noPadding">
        <div class="box-S4">
          <div class="N-Page T-size-24">Seat Layout</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail">
            <!-- <img class="B-img-airport" src="img/SeatLayout.png" /> -->
            <div class="T-TitlePosition T-size-24 nose">NOSE</div>
            <div class="B-seatPosition">
              <div class="seatPosition">
                <img src="/img2/red_d.png" />
              </div>
              <div class="seatPosition">
                <img src="/img2/red_c.png" />
              </div>
              <div class="seatPosition">
                <img src="/img2/red_b.png" />
              </div>
              <div class="seatPosition">
                <img src="/img2/red_a.png" />
              </div>
              <div class="seatPosition">
                <img src="/img2/red_h.png" />
              </div>
              <div class="seatPosition">
                <img src="/img2/red_g.png" />
              </div>
              <div class="seatPosition">
                <img src="/img2/red_f.png" />
              </div>
              <div class="seatPosition">
                <img src="/img2/red_e.png" />
              </div>
              <div class="seatPosition">
                <img src="/img2/red_l.png" />
              </div>
              <div class="seatPosition">
                <img src="/img2/red_k.png" />
              </div>
              <div class="seatPosition">
                <img src="/img2/red_j.png" />
              </div>
              <div class="seatPosition">
                <img src="/img2/red_i.png" />
              </div>
            </div>
            <div class="T-TitlePosition T-size-24 tail">Tail</div>
          </div>
        </div>
      </div>
      <div class="line marginTop"></div>
    </div>
    <div id="maintenanceData" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">Maintenance Data</div>
      </div>
      <div class="box-S4 noPadding flex-between">
        <div class="box-S1-3">
          <div class="N-Page">Next Maintenance</div>
        </div>
        <div class="box-S1-3">
          <div class="N-Page">DUE AT</div>
        </div>
        <div class="box-S1-3">
          <div class="N-Page">Remaining Hours</div>
        </div>
      </div>
      <div class="box-S4 noPadding flex-between">
        <div class="box-S1-3">
          <div class="B-carddetail">
            <div class="t-detail Tcenter T-size-20">25 HRS.</div>
          </div>
        </div>
        <div class="box-S1-3">
          <div class="B-carddetail">
            <div class="t-detail Tcenter T-size-20">4512:56</div>
          </div>
        </div>
        <div class="box-S1-3">
          <div class="B-carddetail">
            <div class="t-detail Tcenter T-size-20">15:11</div>
          </div>
        </div>
      </div>
      <div class="box-S4 noPadding flex-between">
        <div class="box-S1-3">
          <div class="B-carddetail">
            <div class="t-detail Tcenter T-size-20">50 HRS.</div>
          </div>
        </div>
        <div class="box-S1-3">
          <div class="B-carddetail">
            <div class="t-detail Tcenter T-size-20">4510:20</div>
          </div>
        </div>
        <div class="box-S1-3">
          <div class="B-carddetail">
            <div class="t-detail Tcenter T-size-20">12:35</div>
          </div>
        </div>
      </div>
      <div class="box-S4 noPadding flex-between">
        <div class="box-S1-3">
          <div class="B-carddetail">
            <div class="t-detail Tcenter T-size-20">100 HRS.</div>
          </div>
        </div>
        <div class="box-S1-3">
          <div class="B-carddetail">
            <div class="t-detail Tcenter T-size-20">4565:59</div>
          </div>
        </div>
        <div class="box-S1-3">
          <div class="B-carddetail">
            <div class="t-detail Tcenter T-size-20">68:14</div>
          </div>
        </div>
      </div>
      <div class="box-S4 noPadding flex-between">
        <div class="box-S1-3">
          <div class="B-carddetail">
            <div class="t-detail Tcenter T-size-20">150 HRS.</div>
          </div>
        </div>
        <div class="box-S1-3">
          <div class="B-carddetail">
            <div class="t-detail Tcenter T-size-20">4618:23</div>
          </div>
        </div>
        <div class="box-S1-3">
          <div class="B-carddetail">
            <div class="t-detail Tcenter T-size-20">120:38</div>
          </div>
        </div>
      </div>
      <div class="box-S4 noPadding flex-between">
        <div class="box-S1-3">
          <div class="B-carddetail">
            <div class="t-detail Tcenter T-size-20">200 HRS.</div>
          </div>
        </div>
        <div class="box-S1-3">
          <div class="B-carddetail">
            <div class="t-detail Tcenter T-size-20">4668:23</div>
          </div>
        </div>
        <div class="box-S1-3">
          <div class="B-carddetail">
            <div class="t-detail Tcenter T-size-20">170:38</div>
          </div>
        </div>
      </div>
    </div>
    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn
          text
          class="ColorTheme-S white--text theme-btn"
          :to="{
            name: 'ManageAircraft',
            params: { mode: 'View', id: idflight },
          }"
        >
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import fillerdate from "../utils/filterdate";
import feathersClientUOA from "../plugins/feathers-client-uoa";
//Add
import { startOfDay, endOfDay, format } from "date-fns";

export default {
  data: () => ({
    userdata: "",
    readonly_flight: false,
    readonly_pilot: false,
    readonly_eng: false,
    readonly_shore: false,
  }),
  props: ["mode", "idflight", "idaircraft"],
  components: {},
  async mounted() {
    console.log(this.$route.params.mode);
    console.log(this.$route.params.idflight);
    console.log(this.$route.params.idaircraft);
    //alert(this.$route.params.mode + " " + this.$route.params.idflight + " " + this.$route.params.idaircraft)
    this.userdata = this.$CurrentUser.GetCurrentUser();
  },
  filters: {
    setzoro: function (value) {
      return value === null ? 0 : value;
    },
  },
  computed: {},
  methods: {},
};
</script>

<style>
</style>